import {Injectable} from "@angular/core"
import {firstValueFrom, Observable} from 'rxjs'
import { HttpClient } from "@angular/common/http"
import {shareReplay} from "rxjs/operators"

export enum Environment {
  Prod = 'Produktion',
  Abnahme = 'Abnahme',
  Dev = 'Entwicklung',
  Local = 'Lokal',
  Int = 'Integration'
}

export interface Configuration {
  apis: {
    [key: string]: string
  }
  apiUrl: string
  environment: Environment
}

@Injectable({providedIn: 'root'})
export class EnvironmentService {
  private readonly apiUrl = 'assets/config/config.json'
  private configuration$: Observable<Configuration> | undefined
  public apis: any

  constructor(private http: HttpClient) {
    // nothing.
  }

  public load(): Observable<Configuration> {
    return this.http
      .get<Configuration>(`${this.apiUrl}?type=config&ts=` + new Date().getTime())
      .pipe(shareReplay(1))
  }

  async getConfig() {
    if (this.configuration$ === undefined) {
      this.configuration$ = this.load()
    }

    return firstValueFrom(this.configuration$)
  }
}
